import React from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import classNames from 'classnames';

import Container from '../../layouts/container';
import ProjectBreadcrumbs from '../../components/project-breadcrumbs';
import HorizontalRule from '../../components/horizontal-rule';
import Testimonial from '../../components/testimonial';

import { formatDateWithMonth } from '../../utils';

import videoPreferencesDemo from '../../assets/media/preferences-demo.webm';

import styles from './projects.module.scss';

// NOTA BENE: Do better than this in future...
const PAGE = 0;

const PageProject = ({ data }) => {
  const { title, date, thumbnail } = data.pagesJson.portfolio.projects[PAGE];

  return (
    <Container>
      <ProjectBreadcrumbs>{title}</ProjectBreadcrumbs>
      <p className={styles.date}>{formatDateWithMonth(new Date(date))}</p>
      <h1 className={styles.projectTitle}>{title}</h1>

      <section className={styles.headerWithImage}>
        <div className={styles.headerContent}>
          <p className={classNames(styles.paragraph, styles.summary)}>The homepage of Redburn’s research website showcases all the latest research published daily by all teams at the company. However, this can mean the content a client is actually interested in gets lost among the noise. I worked with a team of engineers to design a process by which a client’s email preferences could be used to filter content on their homepage. The result was a feed personalised to each client and editable by them at any time through a simple interface.</p>
          <Testimonial attribution="comment from a Redburn client to our Senior Partner on launch day">Impressive. You are a publisher. Content is King!</Testimonial>
        </div>
        <Img className={styles.headerImage} fluid={data.headerImage.childImageSharp.fluid} />
      </section>


      <HorizontalRule />

      <section className={styles.projectBody}>
        <section className={styles.section}>
          <h2 className={styles.sectionHeader}>Role</h2>
          <p className={styles.paragraph}>I led the design of the new preferences page, working in collaboration with my colleagues on the CRM team to ensure that all the new features being proposed were technically feasible in the timeframe available. I also wanted to make sure that the team were comfortable that any solution would be maintainable in the long run. I was involved in the full process from discovery to delivery.</p>
          <p className={styles.paragraph}>The rest of the team encompassed a group of four engineers, a Project Manager/Scrum Master and a number of senior stakeholders from the business development team.</p>
          <p className={classNames(styles.paragraph, styles.italic)}>Please note: to maintain company and client confidentiality, I am unable to publicly release full content. The details shown below are for illustrative purposes only and should not be shared. All views in this case study are my own and do not necessarily reflect those of Redburn.</p>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionHeader}>Problem</h2>
          <p className={styles.paragraph}>Following the redesign of the homepage of Redburn’s research website, we were confident that clients would be able to now view all content quickly and easily. However, while they could filter the product they received by email to just the topics they were interested in (by requesting a change to the preferences we held for them in our CRM system), they couldn’t do the same for their homepage. Our primary aim therefore was to link up the two systems so that each client received an individualised feed on the website.</p>
          <p className={styles.paragraph}>We carried out some initial surveys to define requirements and learnt that additionally, under the current system, changes to preferences could only be made by Redburn staff — clients had to email or call their sales contact in order to amend which products they received. The second requirement was therefore that clients be handed control of their preferences (for both email and website content) such that they could change them at any time without having to contact Redburn. For those that wished to email or call instead, this functionality would remain.</p>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionHeader}>Design</h2>
          <div className={styles.adjacentImages}>
            <Img fluid={data.preferences_design_01.childImageSharp.fluid} />
            <Img fluid={data.preferences_design_02.childImageSharp.fluid} />
          </div>
          <p className={styles.paragraph}>Initial designs focusing on basic functionality were tested with users to ensure they worked in principle.</p>
          <div className={classNames(styles.imageContainer, styles.imageContainerMd)}>
            <Img fluid={data.preferences_ideation.childImageSharp.fluid} />
          </div>
          <p className={styles.paragraph}>Once everyone was happy that the process would work, I mocked up some mid-fi prototypes. We tested these prototypes with clients and while the overall design was approved, there was some confusion over what the various options did.</p>
          <div className={classNames(styles.imageContainer, styles.imageContainerLg)}>
            <Img fluid={data.preferences_flowchart.childImageSharp.fluid} />
          </div>
          <p className={styles.paragraph}>In discussion with the heads of Sales and the business development team, we added descriptions of each department and a point of contact for those with queries.</p>
          <p className={styles.paragraph}>We also added tooltips on hover so that clients could see what they were signing up for. I worked with the Editorial team to provide the text for each section.</p>
          <div className={classNames(styles.imageContainer, styles.imageContainerMd)}>
            <Img fluid={data.preferences_tooltip.childImageSharp.fluid} />
          </div>
          <p className={styles.paragraph}>This led us to the final designs, which were then implemented by our engineering team.</p>
          <div className={styles.adjacentImages}>
            <Img fluid={data.preferences_design_final_01.childImageSharp.fluid} />
            <Img fluid={data.preferences_design_final_02.childImageSharp.fluid} />
          </div>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionHeader}>Outcome</h2>
          <p className={styles.paragraph}>Since the release of the new functionality in early December, the CRM and Sales teams at Redburn have noticed a significant decrease in requests from clients to update their preferences, with clients entering these choices themselves. In addition, subscribers for a key newsletter jumped from 350 to c1500 in the month following launch.</p>
          <p className={styles.paragraph}>A clip from the promotional explainer video I produced to accompany the launch can be viewed below.</p>
          <video
            src={videoPreferencesDemo}
            poster={data.preferences_video_thumbnail.childImageSharp.fluid.src}
            controls
          />
          <p className={classNames(styles.paragraph, styles.italic)}>Video shortened for copyright reasons.</p>
        </section>
      </section>
    </Container>
  );
};

export const imageQuery = graphql`
  fragment imageQuery on File {
    childImageSharp {
      fluid(maxWidth: 800) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const query = graphql`
{
  pagesJson(portfolio: {projects: {elemMatch: {slug: {regex: "/-/"}}}}) {
    portfolio {
      projects {
        slug
        thumbnail
        title
        date
      }
    }
  }
  headerImage: file(relativePath: { eq: "projects/preferences.png" }) {
    ...imageQuery
  }
  preferences_design_01: file(relativePath: { eq: "projects/preferences-design-01.png" }) {
    ...imageQuery
  }
  preferences_design_02: file(relativePath: { eq: "projects/preferences-design-02.png" }) {
    ...imageQuery
  }
  preferences_design_final_01: file(relativePath: { eq: "projects/preferences-design-final-01.png" }) {
    ...imageQuery
  }
  preferences_design_final_02: file(relativePath: { eq: "projects/preferences-design-final-02.png" }) {
    ...imageQuery
  }
  preferences_flowchart: file(relativePath: { eq: "projects/preferences-flowchart.png" }) {
    ...imageQuery
  }
  preferences_ideation: file(relativePath: { eq: "projects/preferences-ideation.png" }) {
    ...imageQuery
  }
  preferences_tooltip: file(relativePath: { eq: "projects/preferences-tooltip.png" }) {
    ...imageQuery
  }
  preferences_video_thumbnail: file(relativePath: { eq: "projects/preferences-video-thumbnail.png" }) {
    ...imageQuery
  }
}
`;

export default PageProject;
